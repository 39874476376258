<template>
    <div>
      <Home_navbar HeaderClass="fixed-top"></Home_navbar>
      <!-- Breadcrumbs section starts here  -->
      <section class="breadcrumbs">
        <div class="container">   
          <div class="d-flex justify-content-between align-items-center">
             <h2> Portfolios | Professional Troupes </h2>
            <ol>
              <li><router-link to="/">Home</router-link></li>
              <li>Troupes</li>
            </ol>
          </div>  
        </div>
      </section>

      <!-- ======= Article Section Main & Sidebar ======= -->
      <section class="blog">
        <div class="container">
          <div class="row">
            <!-- The main article section starte here -->
            <div class="col-lg-8">
                <!-- Portfolio Search Box & Add Button-->
                <div>
                  <b-row>
                    <b-col sm="8"><b-form-input v-model="SearchBy" placeholder="Search by Troupe name"></b-form-input></b-col>
                      <b-col sm="4" >
                      <p class="tag-rec-cnt" v-if="ShowPageCount>200"> Showing {{PerPage}} of {{ShowPageCount}}</p>
                      <p class="tag-rec-cnt" v-if="ShowPageCount<200"> Showing {{ShowPageCount}} records</p>                      
                    </b-col>                    
                    <!-- <b-col sm="4">
                      <div  class="thira-button" >
                        <router-link to="/share/troupe-portfolio">Submit Your Troupe</router-link>
                      </div> 
                    </b-col>    -->
                  </b-row>    
                </div>
                <!-- Portfolio card Listing goes here -->
                <div class="p-2">
                  <b-row class="mt-3">
                    <b-col sm="6 p-2" v-for="Data in SearchList" v-bind:key="Data.seq_no">     
                      <div class="recent-perform" >
                        <router-link :to="{name:'troupes-detail', params:{ TroupeId:Data.seq_no, SlugName:Data.slug_name}}"><img class="float-left" v-bind:src="Data.troupe_photo" :alt="Data.troupe_name"></router-link>
                          <h4><router-link :to="{name:'troupes-detail', params:{ TroupeId:Data.seq_no, SlugName:Data.slug_name}}">{{Data.troupe_name}}</router-link></h4>
                          <p class="tag-line">{{Data.troupe_pgm_name}} - {{Data.prof_city+' | '+Data.prof_state+' | '+Data.prof_country}}</p>
                      </div> 
                    </b-col>
                  </b-row>
                </div>                  
                <!-- <div id="ThiraCard" v-for="Data in SearchList" v-bind:key="Data.id">
                  <b-row class="row no-gutters profile-card" >
                    <div class="col-md-4 Thira profile-card-5">
                      <div class="card">
                        <div class="card-img-block">
                          <router-link :to="{name:'troupes-detail', params:{ TroupeId:Data.seq_no, SlugName:Data.slug_name}}"><img class="card-img-top" v-bind:src="Data.troupe_photo" alt="Image"></router-link>
                        </div>
                      </div>
                    </div>
                      <div class="col-md-8 Thira profile-card-5">
                        <div class="card-body event-desc-block">
                          <h5 class="page-title-text text-truncate" style="width: 25rem;"><router-link :to="{name:'troupes-detail', params:{ TroupeId:Data.seq_no, SlugName:Data.slug_name}}"><h5>{{Data.troupe_name}}</h5></router-link></h5>
                          <p>{{Data.troupe_pgm_name}} - 
                             {{Data.prof_city+' | '+Data.prof_state+' | '+Data.prof_country}}</p>
                          <p class="card-text text-truncate" ><span v-html="Data.troupe_description"></span>
                          <div class="read-more">
                            <router-link :to="{name:'troupes-detail', params:{ TroupeId:Data.seq_no, SlugName:Data.slug_name}}">Read more</router-link>
                          </div>  
                        </div> 
                      </div>
                  </b-row>    
                </div>  -->
              <b-pagination pills class="mt-5" v-show="ShowPageCount>200"
                v-model="currentPage"
                :total-rows="TotRows"
                :per-page="PerPage"
                aria-controls="ThiraCard"
                @change="handlePageChange"
                align="center"
                size="md"
              ></b-pagination>                                 
            </div>
            <!-- Article Right side bar section starts here -->
            <div class="col-lg-4">
              <div class="sidebar">
                <SideCatgTroupe/>
                <!-- sidebar recent posts-->
                <SideRecentPerformers/>
                <!-- sidebar Filter By Performers Category-->
                <SideFilterPerformers/>  
              </div>

            </div>
            <!-- Article Right side bar Ends here -->
          </div><!-- End row -->
        </div><!-- End container -->
      </section><!-- End Blog Section -->        

      <!-- ======= Inject Footer section here  ======= -->
      <Home_footer/>
    
    </div><!-- End Main Div  -->
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import { CardPlugin } from 'bootstrap-vue'

Vue.use(CardPlugin)
Vue.use(Vueaxios,axios)

  export default {
  name:"ProfessionalTroupes",
  metaInfo: {
    title: "Thiraseela :: ",
    titleTemplate: "%s Professional Troupe-Band Portfolios | Book Stage Shows | Online Program Booking"
  },    
    data() {
      return {
        SearchBy: "",
        items: [],
        RespArray: [],
        // Pagination Variables
        CurrPage: 1,
        MaxPage: 0,
        TotRows: 0,
        PerPage: 200,
        currentPage: 1,
        ShowPageCount: 0,
      }
    },
    watch: {
       '$route' (to)
       {
        if(this.$route.params.Maincatg == 'All') {
          this.ReadAllTroupe(to.params.Maincatg)
        }
        else {
          this.ReadTroupeByCatg(to.params.Maincatg)
        }                 
       }
    },       
    mounted()
    {
      this.GetTotRecCnt()      
      if(this.$route.params.Maincatg == 'All') {
        this.ReadAllTroupe()
      } 
      else {
        this.ReadTroupeByCatg(this.$route.params.Maincatg)
      }           
    },
    methods: {
      ReadAllTroupe(){
        const REQformData1 = new FormData();
        REQformData1.append('Page_No', this.CurrPage);         
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllTroupe',REQformData1)
        .then((resp)=>{
        //resp.data.AllTroupe.forEach(element => this.RespArray.push(element))        
        this.RespArray=resp.data.AllTroupe;
        this.ShowPageCount=this.RespArray.length;
        // console.log(this.ShowPageCount)
      })
      },
      ReadTroupeByCatg(Maincatg){     
        const REQformData = new FormData();
        REQformData.append('MainCatg', Maincatg);
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadTroupeByCatg', REQformData)
        .then((resp)=>{
        //resp.data.AllTroupe.forEach(element => this.RespArray.push(element))                
        this.RespArray=resp.data.AllTroupe;
        this.ShowPageCount=this.RespArray.length;
        // console.log(this.ShowPageCount)
      })
      },
      GetTotRecCnt(){
        const REQformData2 = new FormData();
        REQformData2.append('table_name', 'troupe_profile_main');          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=GetTotRecCnt',REQformData2)
        .then((resp)=>{
        const FirstObject = resp.data.GetTotRecCnt[0];
        this.TotRows = FirstObject.Tot_Rec_Cnt;
        // console.log('TotRows',this.TotRows)
      })
      },
      handlePageChange(value) {
      this.CurrPage = value;
      document.body.scrollIntoView();
      // console.log('this.CurrPage',this.CurrPage)
      if (this.CurrPage > this.MaxPage) 
        {
          this.ReadAllTroupe();
          this.MaxPage = this.CurrPage
        }
      // console.log('this.MaxPage',this.MaxPage)
      },                     
    },
    computed: {
    RespSearchList: function(){
        return this.RespArray.filter((RespArray) => {
        return RespArray.troupe_name.toLowerCase().match(this.SearchBy.toLowerCase())
      });
    },
    SearchList: function()
      {
        return this.RespSearchList.slice(
        (this.currentPage - 1) * this.PerPage,
        this.currentPage * this.PerPage,
        );
      }
  }
  }
</script>

<style scoped>
.recent-perform img {
    width: 120px;
    float: center;
    height: 120px;
    border-radius: 15px;
    padding-right: 7px;

}
.recent-perform h4 {
    font-size: 15px;
    font-weight: bold;
}

.recent-perform h4 a {
    color: #e75480;
    transition: 0.3s;
}

.recent-perform h4 a:hover {
    color: #ff7300;
}

.recent-perform .tag-line {
    display: block;
    /* margin-left: 89px;
    margin-top: -5px; */
    font-style: italic;
    font-size: 14px;
    color: #72afce;
}
</style>
